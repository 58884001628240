.content[data-v-10376740] {
  font-size: 13px;
  margin: 20px;
}
.content .machine[data-v-10376740] {
  display: inline-block;
  font-size: 14px;
  margin-right: 20px;
  font-weight: 700;
}
.content .machine .degree[data-v-10376740] {
  font-size: 20px;
  padding: 4px;
  color: red;
  font-weight: 700;
}
.content .left-content[data-v-10376740] {
  width: 49%;
  margin-right: 1%;
  height: 500px;
  display: inline-block;
}
.content .right-content[data-v-10376740] {
  width: 50%;
  height: 500px;
  display: inline-block;
}
.content .left-content-item[data-v-10376740] {
  width: 100%;
  border: 1px solid #efefef;
}
.content .left-content-item .left-total[data-v-10376740] {
  width: 100%;
  background: #fff;
  height: 30px;
  text-align: left;
  line-height: 30px;
}
.content .left-content-item .left-total span[data-v-10376740] {
  padding-right: 20px;
}
.content .left-content-item .left-title[data-v-10376740] {
  width: 100%;
  background: var(--themeColor, #17a2b8);
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  position: relative;
}
.content .left-content-item .subject[data-v-10376740] {
  background: #efefef;
}
.content .left-content-item .left-box[data-v-10376740] {
  border-right: 1px solid #efefef;
  line-height: 30px;
  height: 30px;
  border-top: 1px solid #efefef;
  text-align: center;
}
.content .left-content-item .left-box input[data-v-10376740] {
  background-color: aliceblue;
}
.content .left-content-item .leftOne[data-v-10376740] {
  border-right: 1px solid #efefef;
  line-height: 30px;
  height: 30px;
  border-top: 1px solid #efefef;
  padding: 0 10px;
}
.content .left-content-item .leftOne span[data-v-10376740] {
  padding-right: 25px;
}
.content .left-content-item[data-v-10376740]:last-child {
  border-right: none;
}
.right-button[data-v-10376740] {
  position: fixed;
  top: 250px;
  right: 0;
  height: 120px;
  width: 40px;
  font-size: 14px;
  margin: 0 auto;
  line-height: 24px;
  background: var(--themeColor, #17a2b8);
  color: #fff;
  border-color: var(--themeColor, #17a2b8);
  border: 0;
  cursor: pointer;
}
.ccyc[data-v-10376740] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}